@import "../../../app//App.scss";

.header {
  background: $brand-color;
  width: 100%;
  @include flex;
}

.header__container {
  @include flex;
  justify-content: space-between;
  padding: 20px 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    padding: 15px 0;
  }

  @media (max-width: 350px) {
    justify-content: center;
    gap: 20px;
  }

  .header__logo-blok {
    @include flex;
    gap: 10px;
  }

  .header__title {
    @include h2;
    color: $white-color;
    font-weight: bolder;

    @media (max-width: 800px) {
      @include h3;
    }

    @media (max-width: 600px) {
      @include h4;
    }
  }

  .header__tel {
    @include h2;
    color: $white-color;

    @media (max-width: 800px) {
      @include h4;
    }

    @media (max-width: 600px) {
      @include h5;
    }

    @media (max-width: 400px) {
      @include body-medium;
    }
  }

  .header__contacts-blok {
    @include flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;

    @media (max-width: 600px) {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
      border-top: 1px solid $white-color;
      padding-top: 15px;
    }

    @media (max-width: 350px) {
      width: 90%;
      margin-top: 0px;
    }
  }
}

.inst__img svg {
  width: 20px;
  height: 20px;
}
