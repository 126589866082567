.contact-us__img {
  width: 550px;

  @media (max-width: 1000px) {
    width: 400px;
  }

  @media (max-width: 800px) {
    display: none;
  }
}
