@import "../../../app//App.variables.scss";

.welcome__container {
  @include flex;
  justify-content: space-between;
  margin-top: $section-margin;

  @media (max-width: 800px) {
    margin-top: 40px;
  }

  @media (max-width: 600px) {
    justify-content: center;
    margin-bottom: 40px;
  }

  @media (max-width: 600px) {
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .welcome__info {
    @media (max-width: 600px) {
      @include flex;
      flex-direction: column;
    }
  }
}

.ant-modal-content {
  background: $brand-color !important;
}

.modal-window {
  padding-bottom: 0 !important;
}
