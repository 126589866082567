@import "../../../app/App.variables.scss";

.contact-us__form-blok {
  width: 100%;
  @include flex;
  flex-direction: column;
}

.contact-us__title {
  @include h2;
  font-size: 62px;
  font-weight: bolder;
  color: $white-color;

  @media (max-width: 800px) {
    font-size: 52px;
  }

  @media (max-width: 600px) {
    font-size: 42px;
  }

  @media (max-width: 400px) {
    font-size: 32px;
  }
}

.contact-us__form {
  margin-top: 60px;
  width: 500px;
  border-radius: 20px;
  padding: 25px;
  @include flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media (max-width: 1000px) {
    width: 400px;
    margin-top: 10px;
    gap: 15px;
  }

  @media (max-width: 800px) {
    gap: 5px;
    margin-bottom: 20px;
  }

  @media (max-width: 400px) {
    width: 90%;
  }

  .form__input {
    width: 100%;
    padding: 20px;
    border-radius: 30px;

    @media (max-width: 1000px) {
      padding: 10px 5px;
    }

    &::placeholder {
      color: $text-color;
      font-size: 20px;

      @media (max-width: 800px) {
        font-size: 18px;
      }

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
  }

  .form__item {
    width: 100%;
  }

  .form__button {
    @include btn;
    border: 4px solid $white-color;
    color: $white-color;
    background: transparent;
    padding: 20px 30px;

    &:hover {
      background: $white-color !important;
      color: $brand-color;
    }

    @media (max-width: 800px) {
      border: 3px solid $white-color;
    }

    @media (max-width: 600px) {
      font-weight: normal;
      border: 2px solid $white-color;
    }
  }
}

.ant-form-item-explain-error {
  color: #a10002;
  text-align: center;
}
