@import "../../../app/App.variables.scss";

.footer {
  background: $brand-color;
  width: 100%;
  @include flex;
  border-top: 1px solid $white-color;
}

.footer__container {
  @include flex;
  justify-content: space-between;
  padding: 30px 0;

  @media (max-width: 800px) {
    padding: 20px 0;
    flex-wrap: wrap;
  }

  @media (max-width: 400px) {
    flex-direction: column;
  }

  .footer__logo-blok {
    @include flex;
    gap: 10px;
  }

  .footer__title {
    @include h3;
    color: $white-color;
    font-weight: bolder;

    @media (max-width: 800px) {
      @include h4;
    }
  }

  .footer__credits-block {
    @include flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 400px) {
      margin-top: 10px;
      border-top: 1px solid $white-color;
      padding-top: 15px;
    }
  }

  .footer__credits-item {
    @include body-medium;
    color: $white-color;
  }

  .footer__contacts-blok {
    @include flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    @media (max-width: 800px) {
      display: none;
    }
  }
}
