@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

$brand-color: #5b85b6;
$brand-color-hover: #5773b1;
$error-color: #ff4d4f;
$warning-color: #faad14;
$white-color: #fafafa;
$gray-color: #8c8c8c;
$gray-color-light: #bfbfbf;
$text-color: rgba(0, 0, 0, 0.85);

$section-margin: 50px;

$main-width: 1400px;

@mixin h1 {
  font-weight: 700;
  font-size: 94px;
  line-height: 130%;
}

@mixin h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 130%;
}

@mixin h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
}

@mixin h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
}

@mixin h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
}

@mixin h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
}

@mixin body-regular {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
}

@mixin body-small {
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
}

@mixin body-medium {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
}

@mixin body-strong {
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
}

@mixin btn {
  border-radius: 40px;
  font-size: 24px;
  font-weight: bold;
  transition: 0.3s;
}

@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
