@import "../../../app//App.variables.scss";

.works__img {
  width: 900px;
  border-radius: 40px;

  @media (max-width: 1000px) {
    width: 780px;
  }

  @media (max-width: 800px) {
    width: 580px;
  }

  @media (max-width: 600px) {
    border-radius: 25px;
    width: 380px;
  }

  @media (max-width: 400px) {
    width: 300px;
  }
}
