@import "../../../app//App.variables.scss";

.about__section {
  background: $brand-color;
  width: 100%;
  @include flex;
  flex-direction: column;
  padding-top: $section-margin;

  @media (max-width: 800px) {
    padding-top: 40px;
  }

  @media (max-width: 600px) {
    padding-top: 30px;
  }
}

.about__container {
  @include flex;
  flex-direction: column;

  .about__icon {
    font-size: 70px;
    color: #fafafa;

    @media (max-width: 800px) {
      font-size: 52px;
    }

    @media (max-width: 600px) {
      font-size: 42px;
    }
  }

  .about__title {
    @include h2;
    font-size: 62px;
    font-weight: bolder;
    color: $white-color;

    @media (max-width: 800px) {
      font-size: 52px;
    }

    @media (max-width: 600px) {
      font-size: 42px;
    }

    @media (max-width: 400px) {
      font-size: 32px;
    }
  }

  .about-items__block {
    margin: 40px 0;

    @include h3;
    font-style: italic;
    color: $white-color;

    @media (max-width: 600px) {
      margin: 30px 0;
      @include h4;
    }

    @media (max-width: 400px) {
      margin: 20px 0;
      @include body-medium;
    }
  }
}
