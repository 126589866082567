@import "../../../app//App.variables.scss";

.welcome__article {
  .welcome__title {
    @include h1;

    & span {
      color: $brand-color;
    }

    @media (max-width: 1000px) {
      font-size: 56px;
    }

    @media (max-width: 800px) {
      font-size: 42px;
    }

    @media (max-width: 600px) {
      text-align: center;
    }

    @media (max-width: 400px) {
      font-size: 32px;
    }
  }

  .welcome__sub-title {
    @include h3;
    font-style: italic;
    margin-bottom: 130px;

    & span {
      color: $brand-color;
    }

    @media (max-width: 1000px) {
      margin-bottom: 80px;
    }

    @media (max-width: 800px) {
      @include h5;
      margin-bottom: 50px;
    }

    @media (max-width: 600px) {
      @include body-medium;
      margin-top: 20px;
      text-align: center;
    }

    @media (max-width: 400px) {
      @include body-medium;
      margin-top: 20px;
      text-align: center;
    }
  }
}
