@import "../../../app/App.variables.scss";

.work-process__item {
  @include flex;
  flex-direction: column;
  gap: 15px;
  width: 70%;

  @media (max-width: 800px) {
    gap: 10px;
  }

  @media (max-width: 600px) {
    width: 95%;
  }

  .item__step {
    font-size: 60px;
    color: $white-color;
    border: 5px solid $white-color;
    border-radius: 70px;
    padding: 5px 20px;

    @media (max-width: 800px) {
      font-size: 40px;
      border: 3px solid $white-color;
      padding: 5px 15px;
    }

    @media (max-width: 600px) {
      font-size: 30px;
      border: 2px solid $white-color;
      padding: 5px 12px;
    }
  }

  .item__title {
    @include h3;
    color: $white-color;
    border-bottom: 1px solid $white-color;
    text-align: center;
    margin-bottom: 5px;

    @media (max-width: 800px) {
      @include h4;
    }
  }

  .item__description {
    @include h6;
    font-style: italic;
    color: $white-color;
    text-align: center;

    @media (max-width: 800px) {
      @include body-regular;
    }
  }
}
