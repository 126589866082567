@import "../../../app/App.variables.scss";

.work-process__section {
  background: $brand-color;
  width: 100%;
  @include flex;
  flex-direction: column;
  padding-top: 50px;

  @media (max-width: 800px) {
    padding-top: 40px;
  }

  @media (max-width: 600px) {
    padding-top: 30px;
  }
}

.work-process__container {
  @include flex;
  flex-direction: column;
  .work-process__title {
    @include h2;
    font-size: 62px;
    font-weight: bolder;
    color: $white-color;

    @media (max-width: 800px) {
      font-size: 52px;
    }

    @media (max-width: 600px) {
      font-size: 42px;
    }

    @media (max-width: 400px) {
      font-size: 32px;
    }
  }

  .work-process__block {
    @include flex;
    align-items: flex-start;
    gap: 50px;
    margin: 50px 0;

    @media (max-width: 800px) {
      margin: 40px 0;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      margin: 30px 0;
      gap: 30px;
    }
  }
}
