@import "../../../app//App.variables.scss";

.logo__img {
  width: 50px;

  @media (max-width: 800px) {
    width: 30px;
  }

  @media (max-width: 600px) {
    width: 15px;
  }
}

.color-style {
  background: $white-color;
  border-radius: 30px;
  overflow: hidden;
  padding: 5px;
}
