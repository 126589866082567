@import "reset-css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@400;500;600;700&display=swap");

@import "./App.variables.scss";

:root {
  font-family: "Montserrat", sans-serif;
  line-height: 1.5;
  font-weight: 400;

  background-color: $white-color;
  color: $text-color;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

body {
  margin: 0 auto;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.app {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

[class*="__container"] {
  width: $main-width;

  @media (max-width: 1440px) {
    width: 1200px;
  }

  @media (max-width: 1200px) {
    width: 1000px;
  }

  @media (max-width: 1000px) {
    width: 800px;
  }

  @media (max-width: 800px) {
    width: 620px;
  }

  @media (max-width: 600px) {
    width: 400px;
  }

  @media (max-width: 400px) {
    width: 350px;
  }
}
