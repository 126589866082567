@import "../../../app//App.variables.scss";

.header__contacts-item {
  @include flex;
  gap: 15px;

  @media (max-width: 600px) {
    gap: 5px;
  }

  & .header__contact-link {
    @include body-medium;
    @include body-small;
    text-decoration: none;
    color: $white-color;
  }

  @media (max-width: 600px) {
    @include body-small;
  }
}
