@import "../../../app/App.variables.scss";

.contact-us__section {
  margin-top: $section-margin;
  background-color: $brand-color;
  width: 100%;
  @include flex;
}

.contact-us__container {
  @include flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    margin-top: 40px;
  }

  @media (max-width: 600px) {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
