@import "../../../app//App.variables.scss";

.app-btn {
  @include btn;
  background: $brand-color;
  border: 4px solid $brand-color;
  color: $white-color;
  padding: 25px 35px;
  text-decoration: none;

  &:hover {
    border: 4px solid $brand-color-hover;
    color: $brand-color-hover;
    background: transparent;
  }

  @media (max-width: 1000px) {
    padding: 20px 30px;
  }

  @media (max-width: 800px) {
    padding: 15px 20px;
    font-size: 20px;
  }

  @media (max-width: 600px) {
    padding: 10px 15px;
    font-size: 18px;
  }
}
